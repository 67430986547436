export default {
  Loading: 'Loading...',
  Header: {
    CreatorDAO: 'Creator DAO',
    VentureDAO: 'Venture DAO',
    GovernanceDAO: 'Governance DAO',
    Wiki: 'Wiki',
    Network: 'Network',
    SignIn: 'Sign In',
    SignOut: 'Sign Out',
    Ethereum: 'Ethereum',
    Profile: 'Profile',
    Directory: 'Directory',
  },
  Home: {
    Hero: {
      Title: 'BeeperDAO',
      ColouredTitle: ' is a Web3 Creator Link-up',
      Subtitle: 'Providing a suite of DAO tools for',
      LaunchButton: 'Launch App',
      WikiButton: 'Wiki',
      TypeWriter: ['Creators', 'Projects', 'Communities'],
      Creators: 'Creators.',
      Projects: 'Projects.',
      Communities: 'Communities.',
    },
    Townhall: {
      Title: 'TOWNHALL',
      Button: 'See All',
      Topic: 'Topic',
      Guest: 'Guest',
      Time: 'Time',
    },
    Projects: {
      Title: 'Featured Creators',
      SubscribeBtn: 'Subscribe',
      SquadBtn: 'Squad',
      MoreBtn: 'See More',
      SellBtn: 'Sell',
    },
    Pools: {
      Title: 'Upcoming Pools / Featured Pools',
      TotalRaise: 'Total Raise',
      MinAllocation: 'Min Allocation',
      MaxAllocation: 'Max Allocation',
      Access: 'Access',
      Time: 'in {time} days',
    },
  },
  Profile: {
    More: 'More',
    UserCard: {
      EditDialog: {
        Title: 'Edit Username',
        InputLabel: 'Username',
        Description:
          'Are you sure you want to edit your username? All of your data will be permanently modified from our servers forever. This action cannot be undone.',
        CancelBtn: 'Cancel',
        SaveBtn: 'Save',
        DAOJoined: 'DAO Joined',
        DAOCreated: 'DAO Created',
      },
    },
    DAO: {
      Title: 'DAO Created',
      SquadBtn: 'Squad',
      EditBtn: 'Edit',
      Deployed: 'Deployed',
      GetPassBtn: 'Get Pass',
      New: 'New',
      Failed: 'Failed',
      Empty: 'No Content',
      EditPanel: {
        Title: 'Edit Basic Information',
        Description: 'Edit your information by filling the information below.',
        Name: 'DAO Name',
        Intro: 'Intro',
        Website: 'Website',
        WhitePaper: 'White Paper',
        Twitter: 'Twitter',
      },
    },
    Pass: {
      Title: 'My PASS',
      TokenID: 'Token ID',
      ExpireAt: 'Expire Date',
    },
  },
  Creator: {
    Banner: {
      Button: 'Create Own DAO',
    },
    List: {
      Title: 'Creator Communities',
      Placeholder: 'DAO Name, DAO ID...',
      Tabs: {
        All: 'All',
        New: 'Latest',
        Hot: 'Hottest',
        PassValid: 'Valid',
        PassInvalid: 'Invalid',
        PassAll: 'All',
      },
    },
    Card: {
      tokenBase: 'ERC20 Based',
      fixedPrice: 'Fixed Price',
      bondingCurve: 'Bonding Curve',
      nftBase: 'NFT Based',
      SubscribeBtn: 'Subscribe',
      SquadBtn: 'Squad',
      GetBtn: 'Get PASS',
      CreatedAt: 'Created At ',
    },
  },
  CreatorCache: {
    Title: 'Create your own DAO community!',
    Description1: 'Join the community with excluesive NFT PASS!',
    Description2: 'Manage the community through townhall and governance!',
    Description3: "It's only one click away!!!",
    CreateBtn: 'Create',
    ExploreBtn: 'Explore First',
    Title2: 'Wanna skip the fuss?',
    Subtitle2: 'how about a random flash townhall?',
    flashdaoBtn: 'Flash DAO',
  },
  CreateDAO: {
    Title: 'DAO Settings',
    Description: 'Create your own PASS with one click away！',
    DAOSettings: {
      Name: 'DAO Name',
      NamePlaceholder: 'Fantastic DAO',
      Intro: 'Intro',
      IntroDesc:
        'Introduce your project within a few sentence, so people can get into your DAO really soon.',
      Logo: 'DAO Logo',
      LogoBtn: 'Select',
      Website: 'Website',
      WhitePaper: 'WhitePaper',
      Twitter: 'Twitter',
      Upload: 'Upload Pic',
    },
    PASSSettings: {
      Title: 'PASS Settings',
      Description:
        'PASS is a one-to-one binding NFT with your DAO with different types. Create the one you shall use, and more info refence is at our wiki.',
      Name: 'PASS Name',
      NamePlaceholder: 'Uranus',
      Symbol: 'Symbol',
      Logo: 'PASS Logo',
      Image: 'Image',
      CreateBtn: 'Create',
      CancelBtn: 'Cancel',
      Ways2Create: {
        Title: 'Ways to create your NFTs',
        TokenBase: 'ERC20 Token Based PASS',
        FixedPrice: 'Fixed Price PASS',
        BondingCurve: 'Bonding Curve PASS',
        NFTBase: 'NFT Based PASS',
      },
      Alert: {
        Name: 'Token Name: ',
        Symbol: 'Symbol: ',
        Decimal: 'Decimal: ',
        Type: 'Token Type: ',
        TotalSupply: 'Total Supply: ',
      },
    },
    TokenBaseOptions: {
      Network: 'Check the network: ',
      Address: 'Address',
      CheckBtn: 'Check',
      PASSContract: 'PASS contract type:',
      FactoryAddr: 'Factory contract address:',
      TotalSupply: 'Total supply:',
      TermOfValidity: 'Term of validity:',
      Burn: 'Burn:',
      ERC20Addr: 'Your ERC20 token contract address:',
      ERC721Addr: 'Your ERC721 token contract address:',
      TokenNeeded: 'Token amount to form a PASS:',
      TokenNeededPlaceholder: '23333',
      Quantity: 'Quantity',
      Price: 'Price',
      Collateral: 'Collateral ERC20 Tokens:',
      Commission: 'Platform Commission:',
      Fee: 'Fee:',
      ReceiveAddr: 'Receiving Address:',
      ReceiveAddrWarn: 'Please confirm your receiving address carefully.',
      InitialPrice: 'Initial Price:',
      Amount: 'Top amount one address can buy:',
    },
    ConfirmDialog: {
      Title: 'Confirm Configuration',
      Description:
        'Confirm the configuration you fill in, once it deployed, some of the configuration cannot be changed.',
      DeployBtn: 'Deploy Now',
      CancelBtn: 'Cancel',
      Network: 'Network',
      ContractType: 'PASS Contract Type',
      ContractAddr: 'Factory Contract Address',
      TotalSupply: 'Total Supply',
      TermOfValidity: 'Term Of Validity',
      Burn: 'Burn',
      ERC20Addr: 'ERC20 Token Address',
      TokenNeeded: 'Token Needed',
      Quantity: 'Quantity',
      Fee: 'Fee',
      Receiver: 'Receiver',
      Amount: 'Amount',
      InitialPrice: 'Initial Price',
    },
    DeployDialog: {
      Title: 'Deploying...',
      Description:
        'The construction of deploying has been sent to your wallet, waiting for execusation receipt, you can check your transaction manually over the corresponding blockchain explorer.',
      BackBtn: 'Go back to home page',
    },
    Success: {
      Label: 'Is Live NOW!',
      Button: 'View it Now!',
    },
  },
  Pass: {
    Welcome: 'Welcome to',
    Claimed: 'claimed!',
    SquadBtn: 'Squad',
    ClaimBtn: 'Claim',
    DeployBtn: 'Continue Deploy',
    Owner: 'Owner',
    NoPass: 'No PASS claimed...',
    Related: {
      Title: 'Related Information',
      Whitepaper: 'Whitepaper',
      Website: 'Website',
      Twitter: 'Twitter',
    },
    Collection: {
      Title: 'PASS Collection',
      MoreBtn: 'More',
      DoneBtn: 'Done',
    },
  },
  Claim: {
    Welcome: 'Welcome to',
    Creator: 'Creator:',
    Address: ' Address:',
    Balance: 'Balance',
    BondingCurve: 'Bonding Curve PASS',
    CurrentPrice: 'Current Price',
    Reserve: 'Reserve',
    CurveIssue: 'Curve Issue',
    SlippageTolerance: 'Slippage Tolerance',
    MaximumCost: 'Maximum Cost',
    NFTBalance: 'Available NFT you possess',
    DepositDesc: 'You need to deposit {amount} {token} in exchange for a PASS.',
    NFTDepositDesc: 'You need to pledge {amount} NFT in exchange for a PASS',
    ApproveErrTitle: 'Approve Failed',
    ApproveErrDesc:
      'Approve have failed. Please check your network connection and try again.',
    ApproveRefuseTitle: 'Approve Refused',
    ApproveRefuseDesc: 'Approve action has been declined by user',
    ClaimRefuseTitle: 'Claim Refused',
    ClaimRefuseDesc: 'Claim action has been declined by user',
    ClaimErrTitle: 'Claim Failed',
    ClaimErrDesc:
      'Claim have failed. Please check your network connection and try again.',
    ApproveBtn: 'Approve',
    ClaimBtn: 'Claim',
    BuyBtn: 'Buy',
    SellBtn: 'Sell',
    SwapBtn: 'Swap',
    NFTAddress: 'NFT Address',
    TokenID: 'Token ID',
    TokenErrMessage: 'Please input correct token ID',
    Select20: 'Select ERC20 Token',
    History: {
      Title: 'History',
      Date: 'Create Date',
      Pass: 'PASS ID',
      Expiry: 'Expire At',
      Action: 'Actions',
      Redeem: 'Redeem',
    },
    SuccessDialog: {
      Title: 'Claim Succeed!',
      Description:
        'You Shall PASS! join our Squad to discuss with other owners or check it in your profile. ',
      SquadBtn: 'Squad',
      ProfileBtn: 'My Profile',
    },
  },
  Footer: {
    Title: '© 2021 BlackMirror Foundation, All Rights Reserved',
    Description:
      "Beeper DAO's shibe add little paw to zapzap coins, give to smol hodl, is gud. shibe ask french cousin for big people talk. jacques very brain, warrior priest, revolutionary, much write. cousin eat rich, much tasty. shibes eat rich too? snacc on rich, make much random, bring coins back. ask zapzap car man, vote for sploot? shibe will ride shiny truck, such wag.",
  },
  Common: {
    Upload: 'Upload',
    ComingSoon: 'Coming Soon ...',
    ScreenSmall: 'Screen is too narrow, use a bigger screen or visit our',
    MobileSite: 'mobile site.',
    '404': '404 Not Found',
    '404Description':
      'Sorry, the page you requested is not found. Please check the URL and try again.',
    AddressCopied: 'Address Copied!',
    AddressCopyDescription:
      'You have successfully copy the address into your clipboard.',
    AuthenticationErrorTitle: 'Authentication Error',
    AuthenticationErrorDesc:
      'The request is not authenticated. Probably with connection error. Please check your network and try again.',
    UploadError: 'Upload Error',
    UploadErrorDescription:
      'The request is not uploaded. Probably with connection error. Please check your network and try again.',
  },
  UploadLangExt: {
    hint: 'Click or drag the file here to upload',
    loading: 'Uploading',
    noSupported:
      'Browser is not supported, please use IE10+ or other browsers.',
    success: 'Upload Success!',
    fail: 'Upload Fail!',
    preview: 'Preview',
    btn: {
      off: 'Cancel',
      close: 'Close',
      back: 'Back',
      save: 'Save',
    },
    error: {
      onlyImg: 'Image only',
      outOfSize: 'File size excceeds the limit',
      lowestPx: 'Image size is too small, expected at least: ',
    },
  },
};
